import { useEffect } from 'react';
import { useFeatureFlags } from '@90poe/feature-flags';
import { token } from '@90poe/auth';
import { initOnboarding } from '../../../sharedServices/onboarding';

export const useOnboarding = () => {
    const flags = useFeatureFlags<{ userflowShellEnable: boolean }>();
    const apiToken = token.getAuthTokenData();

    useEffect(() => {
        if (!flags.userflowShellEnable) {
            return;
        }

        void initOnboarding(apiToken);
    }, [flags.userflowShellEnable, apiToken]);
};
